import * as React from "react"
import { useMapboxClient } from "components/Map/MapboxProvider"
import MapGL from "react-map-gl/mapbox"
import "mapbox-gl/dist/mapbox-gl.css"

export const Map = React.forwardRef(function Map(
  {
    initialViewState,
    width = "500px",
    height = "500px",
    children,
    onClick,
    interactiveLayerIds,
  },
  ref,
) {
  const mapboxClient = useMapboxClient()

  return (
    <MapGL
      mapboxAccessToken={mapboxClient.accessToken}
      style={{
        width,
        height,
      }}
      mapStyle={"mapbox://styles/mapbox/light-v9"}
      initialViewState={initialViewState}
      onClick={onClick}
      ref={ref}
      interactiveLayerIds={interactiveLayerIds}
      attributionControl={false}
    >
      {children}
    </MapGL>
  )
})

import { Marker as BaseMarker } from "react-map-gl/mapbox"
import PropTypes from "prop-types"
import cx from "classnames"

const MARKER_PATH = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`

export const Marker = ({
  latitude,
  longitude,
  variant = "default",
  onClick,
}) => {
  return (
    <BaseMarker latitude={latitude} longitude={longitude}>
      <svg
        height={24}
        viewBox="0 0 24 24"
        className={cx("cursor-pointer", {
          "transform -translate-x-3 -translate-y-4 fill-current":
            variant !== "stopover",
        })}
        onClick={onClick}
      >
        {variant === "stopover" ? (
          <circle
            cx="12"
            cy="12"
            r="8"
            strokeWidth="2"
            className="stroke-primary-default fill-white"
          />
        ) : (
          <path
            d={MARKER_PATH}
            className={cx("fill-current", {
              "text-secondary-default": variant === "departure",
              "text-primary-default": variant === "arrival",
              "text-error-default": variant === "default",
            })}
          />
        )}
      </svg>
    </BaseMarker>
  )
}

Marker.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  variant: PropTypes.oneOf(["default", "departure", "stopover", "arrival"]),
}

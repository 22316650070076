import { UserAvatar } from "domains/user/UserAvatar"
import * as UserStatus from "enums/UserStatus"
import { useTranslator } from "components/Translator"
import { useBreakpoints } from "hooks/useBreakpoints"
import { useRouter } from "components/Router"
import { Section } from "components/Section"
import { Wrapper } from "components/Wrapper"
import { RawLink } from "components/Link"
import cx from "classnames"
import { Text } from "components/Text"
import { Markdown } from "components/Markdown"
import { formatDistanceStrict } from "utils/date"
import { useHomePartialData } from "hooks/useHomePartialData"
import { AvatarSkeleton } from "components/AvatarSkeleton"

export const ActivitySection = () => {
  const translator = useTranslator()
  const { md } = useBreakpoints()
  const router = useRouter()
  const users = useHomePartialData("lastConnectedUsers")

  return (
    <Section>
      <Wrapper>
        <Section.Title size="small">
          {translator.trans("home.index.activity.title", null, "pages")}
        </Section.Title>
      </Wrapper>

      {users.status === "error" ? (
        <Text>
          {translator.trans("home.index.activity.error", null, "pages")}
        </Text>
      ) : null}

      {users.status === "success" && users.value.length === 0 ? (
        <Text>
          {translator.trans("home.index.activity.empty", null, "pages")}
        </Text>
      ) : null}

      {users.status === "loading" ||
      (users.status === "success" && users.value.length > 0) ? (
        <ul className="bg-white overflow-x-auto flex flex-row">
          {users.status === "loading"
            ? Array.from({ length: 3 }).map((_item, index) => {
                return (
                  <li
                    key={index}
                    className="my-2 mr-6 lg:mr-4 last:mr-0 shrink-0"
                  >
                    <ItemSkeleton />
                  </li>
                )
              })
            : null}

          {users.status === "success"
            ? users.value.map((user) => (
                <li
                  key={user.id}
                  className="my-2 mr-6 lg:mr-4 last:mr-0 shrink-0"
                >
                  <RawLink
                    href={router.generate("app_account_profile_show", {
                      userId: user.id,
                    })}
                  >
                    <div className="flex flex-row space-x-4">
                      <div className="relative">
                        <UserAvatar
                          user={user}
                          size={md ? "small" : "medium"}
                        />
                        <div
                          className={cx(
                            {
                              "bg-success-default":
                                user.status?.enum_value == UserStatus.ONLINE,
                              "bg-secondary-default":
                                user.status?.enum_value == UserStatus.OFFLINE,
                            },
                            "absolute -bottom-1 -right-1 w-8 h-8 lg:w-6 lg:h-6 rounded-full border-2 border-white box-border",
                          )}
                        />
                      </div>
                      <div className="flex flex-col space-y-2">
                        <Text variant="body2">
                          <Markdown>
                            {translator.trans(
                              user.status?.enum_value == UserStatus.ONLINE
                                ? "home.index.activity.descriptionOnline"
                                : "home.index.activity.descriptionOffline",
                              { fullName: user.fullName },
                              "pages",
                            )}
                          </Markdown>
                        </Text>

                        <Text className="text-light" variant="caption">
                          {translator.trans(
                            "home.index.activity.lastActivityAt",
                            {
                              lastActivityAt: formatDistanceStrict(
                                new Date(user.lastActivityAt),
                                new Date(),
                              ),
                            },
                            "pages",
                          )}
                        </Text>
                      </div>
                    </div>
                  </RawLink>
                </li>
              ))
            : null}
        </ul>
      ) : null}
    </Section>
  )
}

const ItemSkeleton = () => {
  const { md } = useBreakpoints()

  return (
    <div className="flex flex-row space-x-4 animate-pulse">
      <div className="relative">
        <AvatarSkeleton size={md ? "small" : "medium"} />
      </div>
      <div className="w-40 flex flex-col space-y-2">
        <div className={"w-full h-6 bg-grey-light rounded"}></div>
        <div className={"w-full h-4 bg-grey-light rounded"}></div>
      </div>
    </div>
  )
}
